
function About() {
    return (
      
      
<div className="relative py-16 bg-white overflow-hidden">
  <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
      
    </div>
  </div>
  <div className="relative px-4 sm:px-6 lg:px-8">
    <div className="text-lg max-w-prose mx-auto">
      <h1>
        
        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">What is Coding Mentors?</span>
      </h1>
      <p className="mt-8 text-xl text-gray-500 leading-8">The aim of coding mentors is helping you to learn to code faster. Hope you can find the best coding teachers on this app. And will be able to learn to code distantly and in async way.</p>
    </div>
    
  </div>
</div>

    );
  }

  export default About;